import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import map from "lodash/map"
import get from "lodash/get"
import Post from "../views/layouts/Post"

const NewsPage = props => {
  const news = get(props, "data.newsJson.edges", []);
  return (
    <Post location="新闻">
      {map(news, (item, index) => (
        <div className="single-top" key={index}>
          <div className="left-blog">
            <Link to={`/news/${item.node.slug}`}>
              <Img
                className="img-responsive"
                fluid={get(item, "node.thumbnail.fluid", "")}
              />
            </Link>
          </div>
          <div className="top-blog">
            <Link to={`/news/${item.node.slug}`} className="fast">
              {item.node.title}
            </Link>
            <div className="time">
              <i className="icon-time"></i> {item.node.createdAt}
            </div>
            <Link to={`/news/${item.node.slug}`} className="btn btn-primary">
              了解更多
            </Link>
          </div>
          <div className="clearfix"> </div>
        </div>
      ))}
    </Post>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query NewsQuery {
    newsJson: allContentfulNewsPost {
      totalCount
      edges {
        node {
          title
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          thumbnail {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
